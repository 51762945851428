import React from "react";
import axiosConfig from "../../utils/axiosConfig";

import getProduct from "../../utils/getProduct";

export default class Signout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        axiosConfig("auth/signout").then(res => {
            if (res.data.success === true) {
                document.location.href = getProduct(this.props.location.search)
            }
        })
    }

    render() {
        return null
    }
}