import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button } from "ui-kit-ck-consultant";

import pilotagecabinetWhiteLogo from "../../images/pilotagecabinet-white.svg";
import pixtransferWhiteLogo from "../../images/pixtransfer-white.svg";
import redaxionWhiteLogo from "../../images/redaxion-white.svg";
import evalcarWhiteLogo from "../../images/evalcar-white.svg";
import glasoftwareWhiteLogo from "../../images/glasoftware-white.png";
import ixperienceWhiteLogo from "../../images/ixperience-white.svg";
import assistExpertLogo from "../../images/assistexpert-sigle.svg";
import xpertVisioLogo from "../../images/xpertvisio-sigle.svg";
import valoreasyWhiteLogo from "../../images/valoreasy-white.svg";

import boutiqueImage from "../../images/boutique.png";
import driveImage from "../../images/drive.png";
import formationImage from "../../images/formation.png";
import journalImage from "../../images/journal.png";

import ToolItem from "../../components/ToolItem";
import OtherItem from "../../components/OtherItem";

import AuthContext from "../../context/AuthContext";

export default class Home extends React.Component {
  static contextType = AuthContext;

  render() {
    if (this.context.name.includes("MAITREX")) {
      document.location.href = "https://maitrex.sahadevia.com";
      return null;
    }

    if (!this.context.name) {
      return null;
    }

    return (
      <>
        <div
          style={{
            maxWidth: "1280px",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "20px",
          }}
        >
          <Row>
            <Col xs={12} md={6} lg={4} style={{ marginTop: "40px" }}>
              <div style={{ color: "#ffffff", marginRight: "40px" }}>
                <h2
                  style={{
                    color: "#ffffff",
                    margin: 0,
                    fontSize: "18px",
                    marginBottom: "8px",
                  }}
                >
                  Hubex, plateforme partagée et centralisée
                </h2>
                <h1>Bienvenue {this.context.name}</h1>
                <p>
                  Pour toutes demandes d'accès aux outils, veuillez nous
                  contacter à l'adresse suivante :{" "}
                  <a
                    style={{ color: "#ffffff", display: "inline-block" }}
                    href="mailto:contact@hubex.fr"
                  >
                    contact@hubex.fr
                  </a>
                </p>
              </div>
              {this.context.userRole > 0 ? (
                <Button
                  className="w-auto"
                  orange
                  text="Demande de création d'utilisateur"
                  onClick={() => {
                    window.open(
                      "https://forms.office.com/e/6ddVHPUuFW",
                      "_blank"
                    );
                  }}
                />
              ) : null}
            </Col>
            <Col xs={12} md={6} lg={8} style={{ marginTop: "40px" }}>
              <div style={{ color: "#ffffff" }}>
                <h2
                  style={{
                    color: "#ffffff",
                    margin: 0,
                    fontSize: "18px",
                    marginBottom: "8px",
                  }}
                >
                  Solutions digitales
                </h2>
                <p
                  style={{
                    margin: 0,
                    marginBottom: "14px",
                  }}
                >
                  Accès aux outils internes d'expertise et de management
                </p>
                <Row>
                  <Col xs={6} md={3}>
                    <ToolItem
                      backgroundColor="#4f17a4"
                      title="AssistExpert"
                      logo={assistExpertLogo}
                      url={this.context["URL_ASSISTEXPERT"]}
                      disabled={!this.context.isAssistExpert}
                    />
                  </Col>
                  <Col xs={6} md={3}>
                    {this.context.isEvalCar ? (
                      <ToolItem
                        backgroundColor="#04b2b2"
                        title="EvalCar"
                        logo={evalcarWhiteLogo}
                        url={this.context["URL_EVALCAR"]}
                        disabled={!this.context.isEvalCar}
                      />
                    ) : (
                      <ToolItem
                        backgroundColor="#04b2b2"
                        title="ValorEasy"
                        logo={valoreasyWhiteLogo}
                        url={this.context["URL_VALOREASY"]}
                        disabled={!this.context.isValorEasy}
                      />
                    )}
                  </Col>
                  <Col xs={6} md={3}>
                    <ToolItem
                      backgroundColor="#039161"
                      title="Ixperience"
                      logo={ixperienceWhiteLogo}
                      url={this.context["URL_IXPERIENCE"] + "/hubex"}
                      disabled={!this.context.isIxperience}
                    />
                  </Col>
                  <Col xs={6} md={3}>
                    <ToolItem
                      backgroundColor="#6067ae"
                      title="Pilotage Cabinet"
                      logo={pilotagecabinetWhiteLogo}
                      url={this.context["URL_PILOTAGECABINET"]}
                      disabled={!this.context.isPilotageCabinet}
                    />
                  </Col>
                  <Col xs={6} md={3}>
                    <ToolItem
                      backgroundColor="#63b793"
                      title="PixTransfer"
                      logo={pixtransferWhiteLogo}
                      url={this.context["URL_PIXTRANSFER"]}
                      disabled={!this.context.isPixtransfer}
                    />
                  </Col>
                  <Col xs={6} md={3}>
                    <ToolItem
                      backgroundColor="#cc3000"
                      title="XpertVisio"
                      logo={xpertVisioLogo}
                      url={this.context["URL_XPERTVISIO"]}
                      disabled={!this.context.isXpertVisio}
                    />
                  </Col>
                  <Col xs={6} md={3}>
                    <ToolItem
                      backgroundColor="#7500c9"
                      title="GLA SOFTWARE"
                      logo={glasoftwareWhiteLogo}
                      url={this.context["URL_GLASOFTWARE"]}
                      // disabled={!this.context.isGlaSoftware}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={6} md={3}>
                    <ToolItem
                      backgroundColor="#75abd9"
                      title="Rédaxion"
                      logo={redaxionWhiteLogo}
                      url={this.context["URL_REDAXION"]}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            {this.context.isEvalCar ? (
              <Col xs={12} style={{ marginTop: "40px" }}>
                <Row style={{ marginLeft: "-25px", marginRight: "-25px" }}>
                  <Col
                    xs={12}
                    md={3}
                    style={{ paddingLeft: "25px", paddingRight: "25px" }}
                  >
                    <OtherItem
                      title={"CK Formation"}
                      subtitle={
                        "Accès aux formations proposées par notre centre CK Formation certifié Qualiopi et Datadock."
                      }
                      image={formationImage}
                      titleButton={"Voir les formations"}
                      url="https://www.ck-formation-conseil.fr/"
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={3}
                    style={{ paddingLeft: "25px", paddingRight: "25px" }}
                  >
                    <OtherItem
                      title={"Boutique"}
                      subtitle={
                        "Accès à la liste des produits et kits du Groupe Lang & Associés et Auto Classic by Groupe Lang."
                      }
                      image={boutiqueImage}
                      titleButton={"Voir la boutique"}
                      url="https://boutique-vitrine.webflow.io/"
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={3}
                    style={{ paddingLeft: "25px", paddingRight: "25px" }}
                  >
                    <OtherItem
                      title={"Drive partagé"}
                      subtitle={
                        "Accès à l'ensemble des documents disponibles sur le drive partagé du Groupe Lang & Associés."
                      }
                      image={driveImage}
                      titleButton={"Accéder au Drive"}
                      url="https://drive.google.com/drive/u/0/my-drive"
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={3}
                    style={{ paddingLeft: "25px", paddingRight: "25px" }}
                  >
                    <OtherItem
                      title={"Journal Lang"}
                      subtitle={
                        "Accès à l'ensemble des journaux semestriels du Groupe Lang & Associés."
                      }
                      image={journalImage}
                      titleButton={"Lire les journaux"}
                      url="https://journal-lang.webflow.io/"
                    />
                  </Col>
                </Row>
              </Col>
            ) : null}
          </Row>
        </div>
      </>
    );
  }
}
