import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'ui-kit-ck-consultant/dist/index.css'
import 'ui-kit-ck-consultant/css/style.css'
import "./index.css"

console.log(
  `Name : ${process.env.REACT_APP_NAME}\nVersion : ${process.env.REACT_APP_VERSION}\nDate last version : ${process.env.REACT_APP_DATE}`
)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
