import qs from "qs";

function getProduct(product) {
  product = qs.parse(product.replace("?", ""))
  if (product.hasOwnProperty("product")) {
    return product["product"]
  } else {
    return null
  }
}

export default getProduct;