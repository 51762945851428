import React from "react";

export default class LogoItem extends React.Component {
  render() {
    return (
      <a
        className="opacity"
        style={{
          height: "60px",
          width: "60px",
          backgroundColor: "rgba(255, 255, 255, 0.6)",
          borderRadius: "10%",
          padding: "6px",
          cursor: "pointer",
          margin: "auto",
        }}
        target="_blank"
        rel="noreferrer" 
        href={this.props.href}
      >
        <img
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          src={this.props.src}
          alt={this.props.alt}
        />
      </a>
    );
  }
}
