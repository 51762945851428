import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
export default class OtherItem extends React.Component {
  render() {
    return (
      <div
        style={{
          color: "#ffffff",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          paddingBottom: "16px",
        }}
      >
        <h3
          style={{
            color: "#ffffff",
            margin: 0,
            fontSize: "18px",
            marginBottom: "8px",
          }}
        >
          {this.props.title}
        </h3>
        <p style={{ margin: 0, marginBottom: "16px" }}>{this.props.subtitle}</p>
        <div
          style={{
            position: "relative",
            marginTop: "auto",
          }}
        >
          <img
            style={{
              maxWidth: "100%",
              width: "100%",
              borderRadius: "16px",
              overflow: "hidden",
            }}
            src={this.props.image}
            alt="background"
          />
          <div
            className="animation-opacity"
            style={{
              display: "flex",
              position: "absolute",
              bottom: "4px",
              left: "0px",
              right: "0px",
              padding: "20px",
              cursor: "pointer",
            }}
            onClick={() =>
              !this.props.disabled
                ? window.open(this.props.url, "_blank")
                : false
            }
          >
            <span
              style={{
                fontSize: "14px",
                margin: "auto",
                marginLeft: "0",
                color: "#000000",
              }}
            >
              {this.props.titleButton}
            </span>
            <div
              style={{
                margin: "auto",
                marginRight: "0",
                backgroundColor: "#dddddd",
                display: "flex",
                borderRadius: "8px",
                height: "32px",
                width: "32px",
              }}
            >
              <FontAwesomeIcon
                style={{ color: "#000000", fontSize: "16px", margin: "auto" }}
                icon={faArrowRight}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
