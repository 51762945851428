import React from "react";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPowerOff, faTools } from "@fortawesome/free-solid-svg-icons";
import { Header, ButtonIcon } from "ui-kit-ck-consultant";
import axiosConfig from "../utils/axiosConfig";

import hubexWhiteLogo from "../images/hubex-white.svg";

import AuthContext from "../context/AuthContext";

export default class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      children: this.props.children,
    };
  }

  static contextType = AuthContext;

  handleSignOutClick() {
    axiosConfig("/auth/signout").then((res) => {
      document.location.reload();
    });
  }

  render() {
    if (this.props.connected === false) {
      return <Redirect to="/signin" />;
    } else {
      if (
        this.props.isDefaultPassword &&
        document.location.href !== "https://hubex.fr/setting"
      ) {
        alert("Veuillez modifier votre mot de passse");
        document.location.href = "https://hubex.fr/setting";
      }

      return (
        <div
          className={`Dashboard-container ${
            this.props.special ? "Dashboard-container-special" : ""
          }`}
        >
          <div className="Dashboard-container-body">
            <Header
              logo={hubexWhiteLogo}
              height="42"
              onClick={() => this.props.history.push("/home")}
              styleBackground={{
                backgroundColor: "#343434",
                backgroundImage: "none",
              }}
            >
              {this.context.isAdministration ? (
                <ButtonIcon
                  style={{ color: "#ffffff" }}
                  onClick={() =>
                    (document.location.href = this.context.URL_ADMIN)
                  }
                >
                  <FontAwesomeIcon icon={faTools} />
                </ButtonIcon>
              ) : null}
              <ButtonIcon
                style={{ color: "#ffffff" }}
                onClick={() => this.props.history.push("/setting")}
              >
                <FontAwesomeIcon icon={faUser} />
              </ButtonIcon>
              <ButtonIcon
                style={{ color: "#ffffff" }}
                onClick={() => this.handleSignOutClick()}
              >
                <FontAwesomeIcon icon={faPowerOff} />
              </ButtonIcon>
            </Header>
            <div
              className={`Dashboard-content ${
                this.props.location.pathname.includes("home")
                  ? "Dashboard-content-image"
                  : ""
              }`}
            >
              {this.props.connected === true ? this.props.children : ""}
            </div>
          </div>
        </div>
      );
    }
  }
}
