import React from "react";
import { FormInput, Button, Loader } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";

import LogoItem from "../../components/LogoItem";

import pilotagecabinetLogo from "../../images/sigle-pilotage.png";
import pixtransferLogo from "../../images/sigle-pixtransfer.png";
import redaxionLogo from "../../images/sigle-redaxion.png";
import evalcarLogo from "../../images/sigle-evalcar.png";
import windtransferLogo from "../../images/sigle-windtransfer.png";
import ixperienceLogo from "../../images/sigle-ixperience.png";
import hubexLogo from "../../images/hubex.png";
import assistExpertLogo from "../../images/sigle-assistexpert.png";
import xpertVisioLogo from "../../images/sigle-xpertvisio.png";

import axiosConfig from "../../utils/axiosConfig";

import AuthContext from "../../context/AuthContext";

export default class SigninLight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isError: false,
      isErrorInput: false,
      isLoad: false,
    };
  }

  static contextType = AuthContext;

  handleSignInClick(e) {
    e.preventDefault();

    if (this.state.email && this.state.password) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          axiosConfig("/auth/signin", {
            data: {
              email: this.state.email.replace(/ /g, ""),
              password: this.state.password.replace(/ /g, ""),
            },
          }).then((res) => {
            this.setState({ isLoad: false }, () => {
              if (res.data.success === true) {
                if (res.data.data.isDefaultPassword) {
                  alert("Veuillez modifier votre mot de passse");
                  document.location.href = "https://hubex.fr/setting";
                } else {
                  window.parent.postMessage(
                    {
                      type: "auth",
                      success: true,
                      token: res.data.token,
                    },
                    "*"
                  );
                }
              } else {
                this.setState({ isError: true }, () => {
                  window.parent.postMessage(
                    {
                      type: "auth",
                      success: false,
                    },
                    "*"
                  );
                });
              }
            });
          });
        }
      );
    } else {
      this.setState({
        isErrorInput: true,
      });
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      update: true,
      isError: false,
      isErrorInput: false,
    });
  };

  render() {
    return (
      <>
        <Row className="w-100 overflow-y-auto">
          {window.innerWidth > 540 ? (
            <Col xs={12} md={6} className="d-flex">
              <div className="background-test">
                <img
                  src={hubexLogo}
                  alt="logo Hubex"
                  style={{ maxWidth: "100%", maxHeight: "74px" }}
                />
                <h2 style={{ fontSize: "28px" }}>
                  Plateforme centralisée et partagée
                </h2>
                <div className="d-flex mb-10">
                  <FontAwesomeIcon
                    style={{ fontSize: "18px" }}
                    className="mt-auto mb-auto mr-10"
                    icon={faArrowRight}
                  />
                  <p style={{ fontSize: "22px" }} className="mt-auto mb-auto">
                    Solutions digitales
                  </p>
                </div>
                <div className="d-flex mb-10" style={{ flexWrap: "wrap" }}>
                  <LogoItem
                    href={this.context.URL_DEMO_ASSISTEXPERT}
                    src={assistExpertLogo}
                    alt={"assistExpertLogo"}
                  />
                  <LogoItem
                    href={this.context.URL_DEMO_IXPERIENCE}
                    src={ixperienceLogo}
                    alt={"ixperienceLogo"}
                  />
                  <LogoItem
                    href={this.context.URL_DEMO_PILOTAGECABINET}
                    src={pilotagecabinetLogo}
                    alt={"pilotagecabinetLogo"}
                  />
                  <LogoItem
                    href={this.context.URL_DEMO_PIXTRANSFER}
                    src={pixtransferLogo}
                    alt={"pixtransferLogo"}
                  />
                  <LogoItem
                    href={this.context.URL_DEMO_REDAXION}
                    src={redaxionLogo}
                    alt={"redaxionLogo"}
                  />
                  <LogoItem
                    href={this.context.URL_DEMO_EVALCAR}
                    src={evalcarLogo}
                    alt={"evalcarLogo"}
                  />
                  <LogoItem
                    href={this.context.URL_DEMO_XPERTVISIO}
                    src={xpertVisioLogo}
                    alt={"xpertVisioLogo"}
                  />
                  <LogoItem
                    href={this.context.URL_DEMO_WINDTRANSFER}
                    src={windtransferLogo}
                    alt={"windtransferLogo"}
                  />
                </div>
                <div className="d-flex mb-10">
                  <FontAwesomeIcon
                    style={{ fontSize: "18px" }}
                    className="mt-auto mb-auto mr-10"
                    icon={faArrowRight}
                  />
                  <p style={{ fontSize: "22px" }} className="mt-auto mb-auto">
                    Formations certifiées
                  </p>
                </div>
                <div className="d-flex mb-10">
                  <FontAwesomeIcon
                    style={{ fontSize: "18px" }}
                    className="mt-auto mb-auto mr-10"
                    icon={faArrowRight}
                  />
                  <p style={{ fontSize: "22px" }} className="mt-auto mb-auto">
                    Boutiques
                  </p>
                </div>
                <div className="d-flex mb-10">
                  <FontAwesomeIcon
                    style={{ fontSize: "18px" }}
                    className="mt-auto mb-auto mr-10"
                    icon={faArrowRight}
                  />
                  <p style={{ fontSize: "22px" }} className="mt-auto mb-auto">
                    Drive partagé
                  </p>
                </div>
              </div>
            </Col>
          ) : (
            <Col xs={12} md={6} className="d-flex">
              <div className="mt-auto ml-auto mr-auto text-center">
                <img
                  src={hubexLogo}
                  alt="logo Hubex"
                  style={{ maxWidth: "100%", maxHeight: "74px" }}
                />
                <h4 style={{ fontSize: "28px" }}>
                  Plateforme centralisée et partagée
                </h4>
              </div>
            </Col>
          )}
          <Col xs={12} md={6} className="mt-20 mb-20 d-flex">
            <div
              className="m-auto bg-white"
              style={{
                borderRadius: "15px",
                padding: "40px",
              }}
            >
              <h2 className="m-0 mb-10">Connexion</h2>
              <FormInput
                className="mb-10"
                title="Identifiant"
                placeholder="exemple@hubex.fr"
                name="email"
                type="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
              />

              <FormInput
                className="mb-20"
                title="Mot de passe"
                placeholder="*********"
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.handleInputChange}
                required
                error={
                  this.state.isError
                    ? "Identifiant ou mot de passe incorrects"
                    : ""
                }
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.handleSignInClick(e);
                  }
                }}
              />

              <div className="d-flex">
                <Button
                  className="mb-20 w-auto ml-auto"
                  text="Se connecter"
                  onClick={(e) => this.handleSignInClick(e)}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
              <Link
                to={{ pathname: "/forgot" }}
                style={{ color: "var(--primary-color)" }}
              >
                Mot de passe oublié ?
              </Link>
            </div>
          </Col>
        </Row>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
