import React from "react";
import { Redirect } from "react-router-dom";
import { Error404 } from "ui-kit-ck-consultant";

// Layout Types
import AuthLayout from "./layouts/Auth";
import AuthSpecialV2 from "./layouts/AuthSpecialV2";
import DashboardLayout from "./layouts/Dashboard";

// Route Views
import Signin from "./views/auth/Signin";
import SigninLight from "./views/auth/SigninLight";
import Forgot from "./views/auth/Forgot";
import Signout from "./views/auth/Signout";
import Home from "./views/dashboard/Home";
import Setting from "./views/dashboard/Setting";

const routes = [
  {
    path: "/",
    exact: true,
    layout: AuthLayout,
    component: () => <Redirect to="/signin" />,
  },
  {
    path: "/signin",
    exact: true,
    layout: AuthSpecialV2,
    component: Signin,
  },
  {
    path: "/signin-light",
    exact: true,
    layout: AuthSpecialV2,
    component: SigninLight,
  },
  {
    path: "/forgot",
    exact: true,
    layout: AuthLayout,
    component: Forgot,
  },
  {
    path: "/signout",
    exact: true,
    layout: AuthLayout,
    component: Signout,
  },
  {
    path: "/home",
    exact: true,
    special: true,
    layout: DashboardLayout,
    component: Home,
  },
  {
    path: "/setting",
    exact: true,
    layout: DashboardLayout,
    component: Setting,
  },
  {
    layout: Error404,
    component: null,
  },
];

export default routes;
