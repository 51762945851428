import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import routes from "./routes";

import axiosConfig from "./utils/axiosConfig";

import Alert from "./components/Alert";

import AuthContext from "./context/AuthContext";

import ReactGA from "react-ga4";
ReactGA.initialize("G-JJ9JJ1SNR7");

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      connected: null,
      token: null,
      isDefaultPassword: false,
      isAdministration: false,
      isPilotageCabinet: false,
      isPixtransfer: false,
      isValorEasy: false,
      isRedaxion: false,
      isXpertVisio: false,
      isIxperience: false,
      isGlaSoftware: false,
      name: "",
      userRole: null,
      URL_VALORSEASY: null,
      URL_PILOTAGECABINET: null,
      URL_GLASOFTWARE: null,
      URL_PIXTRANSFER: null,
      URL_IXPERIENCE: null,
      URL_REDAXION: null,
      URL_ASSISTEXPERT: null,
      URL_XPERTVISIO: null,
      URL_ADMIN: null,
    };
  }

  componentDidMount() {
    this.checkAuth();
    this.intervalToken = setInterval(() => {
      this.checkAuth();
    }, 30 * 60 * 1000);
  }

  checkAuth = () => {
    if (!document.location.href.includes("signout")) {
      axiosConfig("/auth/token").then((res) => {
        if (res.data.success === true) {
          const oldState = this.state;
          const newState = {
            isReady: true,
            connected: true,
            token: res.data.token,
            isDefaultPassword: res.data.data.isDefaultPassword,
            isAdministration: res.data.data.isAdministration,
            isPilotageCabinet: res.data.data.isPilotageCabinet,
            isPixtransfer: res.data.data.isPixtransfer,
            isValorEasy: res.data.data.isValorEasy,
            isEvalCar: res.data.data.isEvalCar,
            isRedaxion: res.data.data.isRedaxion,
            isXpertVisio: res.data.data.isXpertVisio,
            isIxperience: res.data.data.isIxperience,
            isGlaSoftware: res.data.data.isGlaSoftware,
            isAssistExpert: res.data.data.isAssistExpert,
            name: res.data.data.name,
            userRole: res.data.data.userRole,
            URL_VALORSEASY: res.data.url.URL_VALORSEASY,
            URL_EVALCAR: res.data.url.URL_EVALCAR,
            URL_PILOTAGECABINET: res.data.url.URL_PILOTAGECABINET,
            URL_GLASOFTWARE: res.data.url.URL_GLASOFTWARE,
            URL_PIXTRANSFER: res.data.url.URL_PIXTRANSFER,
            URL_IXPERIENCE: res.data.url.URL_IXPERIENCE,
            URL_REDAXION: res.data.url.URL_REDAXION,
            URL_ASSISTEXPERT: res.data.url.URL_ASSISTEXPERT,
            URL_XPERTVISIO: res.data.url.URL_XPERTVISIO,
            URL_ADMIN: res.data.url.URL_ADMIN,
          };
          if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
            this.setState(newState);
          }
        } else {
          const oldState = this.state;
          const newState = {
            isReady: true,
            connected: false,
            token: null,
            isAdministration: false,
            isPilotageCabinet: false,
            isPixtransfer: false,
            isValorEasy: false,
            isEvalCar: false,
            isRedaxion: false,
            isXpertVisio: false,
            isIxperience: false,
            isGlaSoftware: false,
            isAssistExpert: false,
            name: "",
            URL_VALORSEASY: null,
            URL_EVALCAR: null,
            URL_PILOTAGECABINET: null,
            URL_GLASOFTWARE: null,
            URL_PIXTRANSFER: null,
            URL_IXPERIENCE: null,
            URL_REDAXION: null,
            URL_ASSISTEXPERT: null,
            URL_XPERTVISIO: null,
            URL_ADMIN: null,
          };
          if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
            this.setState(newState);
          }
        }
      });
    } else {
      this.setState({ isReady: true, connected: false });
    }
  };

  render() {
    if (!this.state.isReady) {
      return <></>;
    }

    return (
      <>
        <AuthContext.Provider
          value={{
            isAdministration: this.state.isAdministration,
            isPilotageCabinet: this.state.isPilotageCabinet,
            isPixtransfer: this.state.isPixtransfer,
            isValorEasy: this.state.isValorEasy,
            isEvalCar: this.state.isEvalCar,
            isRedaxion: this.state.isRedaxion,
            isXpertVisio: this.state.isXpertVisio,
            isIxperience: this.state.isIxperience,
            isGlaSoftware: this.state.isGlaSoftware,
            isAssistExpert: this.state.isAssistExpert,
            name: this.state.name,
            userRole: this.state.userRole,
            URL_VALORSEASY: this.state.URL_VALORSEASY,
            URL_EVALCAR: this.state.URL_EVALCAR,
            URL_PILOTAGECABINET: this.state.URL_PILOTAGECABINET,
            URL_GLASOFTWARE: this.state.URL_GLASOFTWARE,
            URL_PIXTRANSFER: this.state.URL_PIXTRANSFER,
            URL_IXPERIENCE: this.state.URL_IXPERIENCE,
            URL_REDAXION: this.state.URL_REDAXION,
            URL_ASSISTEXPERT: this.state.URL_ASSISTEXPERT,
            URL_XPERTVISIO: this.state.URL_XPERTVISIO,
            URL_ADMIN: this.state.URL_ADMIN,
            URL_DEMO_EVALCAR: "https://evalcar.webflow.io/",
            URL_DEMO_PILOTAGECABINET: "https://pilotage-cabinet.webflow.io/",
            URL_DEMO_WINDTRANSFER: "http://windtransfer.webflow.io/",
            URL_DEMO_PIXTRANSFER: "https://pixtransfer.webflow.io/",
            URL_DEMO_IXPERIENCE: "https://ixperience.webflow.io/",
            URL_DEMO_REDAXION: "https://redaxion.webflow.io/",
            URL_DEMO_ASSISTEXPERT: "https://assistexpert.webflow.io/",
            URL_DEMO_XPERTVISIO: "https://xpertvisio.webflow.io/",
          }}
        >
          <Router basename={process.env.REACT_APP_BASENAME || ""}>
            <Switch>
              {routes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={(props) => {
                      return (
                        <route.layout
                          {...props}
                          special={route.special ? true : false}
                          connected={this.state.connected}
                          isDefaultPassword={this.state.isDefaultPassword}
                          token={this.state.token}
                        >
                          <route.component {...props} />
                        </route.layout>
                      );
                    }}
                  />
                );
              })}
            </Switch>
          </Router>
        </AuthContext.Provider>
        {/* <Modal
          large
          show={!window.localStorage.getItem("hideGIF_newYear2023")}
          title="Bonne année 2023 !"
          onClose={() => {
            window.localStorage.setItem("hideGIF_newYear2023", "1");
            window.document.location.reload();
          }}
        >
          <p>
            Bonjour {this.state.name}, L’équipe de développement te souhaite une
            bonne année 2023.
          </p>
          <img
            src={gifNewYear}
            alt="new-year"
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        </Modal> */}
        <Alert />
      </>
    );
  }
}
