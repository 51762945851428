import React from "react";

export default class ToolItem extends React.Component {
  render() {
    return (
      <div
        className={`tool-item-container ${
          this.props.disabled ? "disabled" : ""
        }`}
        style={{
          backgroundColor: this.props.backgroundColor,
        }}
        onClick={() =>
          !this.props.disabled ? window.open(this.props.url, "_blank") : false
        }
      >
        <div style={{}}></div>
        <img src={this.props.logo} alt="logo-product" />
        <span>{this.props.title}</span>
      </div>
    );
  }
}
