import React from "react";
import { Redirect } from "react-router-dom";
import { Loader } from "ui-kit-ck-consultant";

import getProduct from "../utils/getProduct";

export default class AuthSpecialV2Layout extends React.Component {
  render() {
    if (this.props.connected === null) {
      return "";
    } else if (this.props.connected === true) {
      if (document.location.href.includes("signin-light")) {
        window.parent.postMessage(
          {
            type: "auth",
            success: true,
            token: this.props.token,
          },
          "*"
        );

        return <Loader show={true} />;
      }
      if (this.props.location.search) {
        document.location.href =
          getProduct(this.props.location.search) + this.props.token;
        return <Loader show={true} />;
      } else {
        return <Redirect to="/home" />;
      }
    } else {
      return <div className="Auth">{this.props.children}</div>;
    }
  }
}
