import React from "react";
import { Redirect } from "react-router-dom";

import getProduct from "../utils/getProduct";

export default class AuthLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      children: this.props.children,
    };
  }

  render() {
    if (this.props.connected === null) {
      return "";
    } else if (this.props.connected === true) {
      if (this.props.location.search) {
        document.location.href =
          getProduct(this.props.location.search) + this.props.token;
      } else {
        return <Redirect to="/home" />;
      }
    } else {
      return (
        <div className="Auth">
          <div className="Auth-container">{this.state.children}</div>
        </div>
      );
    }
  }
}
