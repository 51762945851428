import React from "react";
import { Button } from "ui-kit-ck-consultant";

export default class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      callback: null,
      text: "",
    };
  }

  componentDidMount() {
    window.alert = (text) => {
      this.setState({
        isShow: true,
        text,
      });
    };

    window.confirm = (text, callback) => {
      this.setState({
        isShow: true,
        callback,
        text,
      });
    };
  }

  render() {
    if (!this.state.isShow) {
      return null;
    }

    return (
      <div
        style={{
          display: "flex",
          zIndex: "1000",
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
      >
        <div
          className="m-auto bg-white p-20"
          style={{
            borderRadius: "12px",
            maxWidth: "500px",
          }}
        >
          <p className="text-center m-0 mb-10">{this.state.text}</p>
          {this.state.callback ? (
            <div className="d-flex">
              <Button
                red
                onClick={() => {
                  const callback = this.state.callback;
                  this.setState(
                    { isShow: false, callback: null, text: "" },
                    () => callback(false)
                  );
                }}
                text="Non"
                className="mr-10"
              />
              <Button
                onClick={() => {
                  const callback = this.state.callback;
                  this.setState(
                    { isShow: false, callback: null, text: "" },
                    () => callback(true)
                  );
                }}
                text="Oui"
              />
            </div>
          ) : (
            <Button
              onClick={() =>
                this.setState({ isShow: false, callback: null, text: "" })
              }
              text="OK !"
            />
          )}
        </div>
      </div>
    );
  }
}
