import React, { createRef } from "react";
import {
  Nav,
  FormInput,
  Button,
  Signature,
  Modal,
  Card,
  Dropzone,
  Loader,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import axiosConfig from "../../utils/axiosConfig";
import SignatureCanvas from "react-signature-canvas";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Setting.css";

import AuthContext from "../../context/AuthContext";

export default class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      isErrorFormat: false,
      signature: "",
      modalSignature: false,
      height: 200,
      width: 200,
      picture: null,
      isLoad: false,
      preview: null,
    };
    this.signatureRef = createRef();
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate() {
    if (
      this.signatureRef.current &&
      this.state.width !== this.signatureRef.current.getCanvas().offsetWidth
    ) {
      this.setState({
        width: this.signatureRef.current.getCanvas().offsetWidth,
      });
    }
  }

  handleChangePasswordClick() {
    axiosConfig("/auth/change-password", {
      data: {
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword,
      },
    }).then((res) => {
      if (res.data.success === true) {
        alert("Le mot de passe a été modifié");
        this.props.history.push("/signin");
      }
    });
  }

  componentDidMount() {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/auth/signature", {
        method: "POST",
      }).then((res) => {
        if (res.data.success === true) {
          this.setState({
            signature: res.data.signature,
            isLoad: false,
          });
        } else {
          this.setState({ isLoad: false });
        }
      });
      axiosConfig("/auth/picture", {
        method: "POST",
      }).then((res) => {
        if (res.data.success === true) {
          this.setState({ picture: res.data.picture, isLoad: false });
        }
      });
    });
  }

  handleRemoveSignatureClick(e) {
    axiosConfig("/auth/remove-signature", {
      method: "POST",
    }).then((res) => {
      if (res.data.success === true) {
        this.setState({ signature: "" });
      } else {
        alert("Une erreur est survenue lors de la suppresion de la signature");
      }
    });
  }

  handleModalSignatureClick(e) {
    if (this.state.modalSignature) {
      this.setState({ modalSignature: false });
    } else {
      this.setState({ modalSignature: true });
    }
  }

  handleClearSignatureClick = () => {
    this.signatureRef.current.clear();
  };

  handleValidSignatureClick = () => {
    axiosConfig("/auth/add-signature", {
      method: "POST",
      data: {
        signature: this.signatureRef.current
          .getTrimmedCanvas()
          .toDataURL("image/png"),
      },
    }).then((res) => {
      if (res.data.success === true) {
        this.setState({
          signature: this.signatureRef.current
            .getTrimmedCanvas()
            .toDataURL("image/png"),
          modalSignature: false,
        });
      } else {
        alert(
          "Une erreur est survenue lors de la modification de la signature"
        );
      }
    });
  };

  blobValue = (e) => {
    this.setState({
      dataBlob: e.base64,
      dataMime: e.mime,
      preview: e.base64,
    });
  };

  handleClick = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/auth/update-picture", {
        data: {
          base64: this.state.dataBlob,
          mime: this.state.dataMime,
        },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState({
            picture: this.state.dataBlob,
            preview: null,
            dataBlob: null,
            isLoad: false,
          });
        } else {
          this.setState({ isLoad: false });
        }
      });
    });
  };

  render() {
    return (
      <>
        <Nav
          className="mb-10 mb-30-xl"
          title={`Paramètres - ${this.context.name}`}
          back
          onBack={this.props.history.goBack}
        />
        <Card title="Mot de passe" className="mb-20">
          <Row>
            <Col sm={12} md={3}>
              <FormInput
                className="mb-20"
                type="password"
                name="oldPassword"
                value={this.state.oldPassword}
                title="Ancien mot de passe"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col sm={12} md={3}>
              <FormInput
                className="mb-20"
                type="password"
                name="newPassword"
                value={this.state.newPassword}
                title="Nouveau mot de passe"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col sm={12} md={3}>
              <FormInput
                className="mb-20"
                type="password"
                name="confirmPassword"
                value={this.state.confirmPassword}
                title="Confirmer nouveau mot de passe"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col sm={12} md={3} className="d-flex">
              {console.log(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{11,}/.test(
                  this.state.newPassword
                ),
                this.state.newPassword,
                !!this.state.newPassword,
                !!this.state.oldPassword,
                !!this.state.confirmPassword,
                this.state.newPassword === this.state.confirmPassword
              )}
              <Button
                disabled={
                  !(
                    this.state.oldPassword &&
                    this.state.newPassword &&
                    this.state.confirmPassword &&
                    this.state.newPassword === this.state.confirmPassword &&
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{11,}/.test(
                      this.state.newPassword
                    )
                  )
                }
                className="mt-auto mb-20"
                text="Modifier"
                onClick={() => this.handleChangePasswordClick()}
              />
            </Col>
            <Col xs={12}>
              <p
                className={`text-center mt-0  ${
                  !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{11,}/.test(
                    this.state.newPassword
                  )
                    ? "red"
                    : ""
                }`}
              >
                Nouveau format : 1 nombre / 1 majuscule / 1 caractère spécial (@$!%*?&) /
                11 caractères
              </p>
            </Col>
          </Row>
        </Card>

        <Row>
          <Col xs={12} md={6}>
            <Card title="Signature">
              <Signature
                signature={this.state.signature}
                onAdd={(e) => this.handleModalSignatureClick(e)}
                onRemove={(e) => this.handleRemoveSignatureClick(e)}
              />
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card title="Photo de profil">
              <div className={"container"}>
                {this.state.picture ? (
                  <div
                    className="m-auto"
                    style={{
                      position: "relative",
                      height: "200px",
                      width: "200px",
                      borderRadius: "200px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        transform: "translate(-50%, -50%)",
                        left: "50%",
                        top: "50%",
                        minWidth: "200px",
                        minHeight: "200px",
                        objectFit: "contain",
                      }}
                      src={this.state.picture}
                      alt="profil"
                    />
                  </div>
                ) : (
                  <FontAwesomeIcon icon={faUser} />
                )}
                <Dropzone
                  className={"m-auto my-10"}
                  dropHandler={this.blobValue}
                  typeName={["jpeg", "png"]}
                  typeAccept={["image/jpeg", "image/png"]}
                />
                {this.state.dataBlob ? (
                  <>
                    {this.state.preview ? (
                      <div>
                        <h4>Aperçu : </h4>
                        <img
                          style={{
                            width: "100px",
                            position: "relative",
                            borderRadius: "50%",
                          }}
                          src={this.state.preview}
                          alt="profil"
                        />
                      </div>
                    ) : null}
                    <Button
                      text={"Valider"}
                      onClick={() => this.handleClick()}
                    />
                  </>
                ) : null}
              </div>
            </Card>
          </Col>
        </Row>

        <Modal
          title="Ajouter une signature"
          show={this.state.modalSignature}
          onClose={(e) => this.handleModalSignatureClick(e)}
        >
          <SignatureCanvas
            ref={this.signatureRef}
            penColor="black"
            canvasProps={{
              height: this.state.height,
              width: this.state.width,
              className: "sigCanvas",
            }}
          />
          <Button
            text="Rénitialiser"
            className="m-0 mb-10 outline"
            onClick={this.handleClearSignatureClick}
          />
          <Button
            text="Valider"
            className="m-0"
            onClick={this.handleValidSignatureClick}
          />
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
