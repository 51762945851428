import React from "react";
import axiosConfig from "../../utils/axiosConfig";
import { FormInput, Button, Loader } from "ui-kit-ck-consultant";
import { Link } from "react-router-dom";

import hubexLogo from "../../images/hubex.svg";

export default class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      product: this.props.location.state
        ? this.props.location.state.product
        : "",
      email: this.props.location.state ? this.props.location.state.email : "",
      success: false,
    };
  }

  handleForgotClick(e) {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        e.preventDefault();
        axiosConfig("/auth/forgot", {
          method: "POST",
          data: {
            email: this.state.email,
          },
          withCredentials: true,
          responseType: "json",
        }).then((res) => {
          if (res.data.success === true) {
            this.setState({ success: true, isLoad: false });
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Erreur lors du renvoi de mot de passe");
            });
          }
        });
      }
    );
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      update: true,
    });
  };

  render() {
    return (
      <>
        <div>
          <img
            alt="logo"
            className="d-block ml-auto mr-auto mb-20"
            style={{ maxWidth: "100%" }}
            height="60"
            src={hubexLogo}
          />
          <h4>Mot de passe oublié</h4>
          {this.state.success === false ? (
            <>
              <p className="mb-20">
                Saissiez votre adresse e-mail, un nouveau mot de passe vous sera
                envoyé
              </p>
              <FormInput
                className="mb-20"
                title="Adresse mail"
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
              <Button
                className="mb-20"
                text="Envoyer le nouveau mot de passe"
                onClick={(e) => this.handleForgotClick(e)}
              />
            </>
          ) : (
            <p className="mb-20 text-center">
              Nous vous avons envoyé votre nouveau mot de passe à l'adresse{" "}
              <b>{this.state.email}</b>
            </p>
          )}
          <Link
            to={{
              pathname: "/signin",
              state: {
                product: this.state.product,
                email: this.state.email,
              },
            }}
          >
            Retour
          </Link>
        </div>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
